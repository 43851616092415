import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Chip, IconButton, Typography, Table, TableBody, TableHead, TableRow, TableContainer, TableCell, Box, Grid, Paper } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Create';
import { useParams, useHistory } from "react-router-dom";
import AppLayout from '../layout/app';
import { fetchAPI, setkey } from '../utils';
import moment from "moment";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    formLable: {
        textAlign: 'center'
    }
}));

export default function SurveyListview() {

    const classes = useStyles();
    const history = useHistory();
    const [list, setList] = useState([]);
    const [currentTime, setCurrentTime] = useState('');
    let { id } = useParams();

    const getFormList = () => {
        fetchAPI('GET', `survey/user/${id}`).then(respObj => {
            console.log(respObj)
            setList(respObj)
            setkey(id)
        }).catch();

    }

    useEffect(() => {
        getFormList();
        const interval = setInterval(() => {
            setCurrentTime(moment().format("HH:mm:ss").toString());
        }, 1000);
    }, []);


    return (
        <AppLayout>
            <Container maxWidth="md">
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    style={{ maxWidth: '100%' }}
                >
                    <Grid item xs={12} align="center">
                        <Box style={{ backgroundColor: "white" }} py={2}>
                            <img style={{ borderRadius: "10px" }} src="/imgs/bank.png" width="65%" alt="bank" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} align="left">
                        <Paper elevation={0}>
                            {false ?
                                (
                                    <TableContainer>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        <Box p={2}>
                                                            <Typography align="center" variant="h6">2024 Year End Behavioural Appraisal List</Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        <Box p={2}>
                                                            <Typography align="center" variant="h6">The survey has ended. Thank you for your feedback. We really appreciate your time.</Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                        </Table>
                                    </TableContainer>) : (
                                    <TableContainer>
                                        <Table size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell colSpan={5}>
                                                        <Box p={2}>
                                                            <Typography align="center" variant="h6">2024 Year End Behavioural Appraisal List</Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Status</TableCell>
                                                    <TableCell>Appraisal For</TableCell>
                                                    <TableCell>Appraisal By</TableCell>
                                                    <TableCell>Completed On</TableCell>
                                                    <TableCell></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {list.map((row) => (
                                                    <TableRow
                                                        key={row._id}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        {row.completed ? (<TableCell align="left"><Chip label="Completed" size="small" style={{ backgroundColor: '#2e7d32', color: '#fff' }} /></TableCell>) : (<TableCell align="left"><Chip label="Not Completed" size="small" style={{ backgroundColor: '#d32f2f', color: '#fff' }} /></TableCell>)}
                                                        <TableCell align="left">{row.appraisalFor.Name}</TableCell>
                                                        <TableCell component="th" scope="row">
                                                            {row.appraisalCompleteBy.Name}
                                                        </TableCell>
                                                        {row.completedAt ? (<TableCell align="left">{moment(row.completedAt).format("DD-MM-YYYY")}</TableCell>) : (<TableCell></TableCell>)}
                                                        <TableCell align="right"> <Box ml={1}><IconButton size="small" aria-label="Edit" onClick={() => history.push(`/form/${row._id}`)}>
                                                            {row.completed ? "" : <EditIcon fontSize="inherit" />}
                                                        </IconButton></Box></TableCell>
                                                    </TableRow>
                                                ))}
                                                <TableRow>
                                                    <TableCell style={{ border: 0 }} colSpan={5}>
                                                        <Box m={4}></Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                )}
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </AppLayout>
    )
}
