import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Button, Typography, Radio, RadioGroup, Box, Grid, Paper } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useParams, useHistory } from "react-router-dom";
import AppLayout from '../layout/app';
import { getkey } from '../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    formLable: {
        textAlign: 'center'
    }
}));

export default function Thank() {

    const classes = useStyles();
    const history = useHistory();

    return (
        <AppLayout>
            <Container maxWidth="md">
                <Grid
                    container
                    spacing={0}
                    justifyContent="center"
                    style={{ minHeight: '50vh', maxWidth: '100%' }}
                >
                    <Grid item xs={12} align="center">
                        <Box style={{ backgroundColor: "white" }} py={2}>
                            <img style={{ borderRadius: "10px" }} src="/imgs/bank.png" width="65%" alt="bank" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} align="center">
                        <Paper elevation={0}>
                            <Box p={3}>
                                <Typography variant="h4">Thank you for completing the Appraisal</Typography>
                                <Typography variant="body2" style={{ marginTop: "10px" }}>
                                    Your response has been recorded
                                </Typography>
                                <Button style={{ marginTop: "30px" }} onClick={() => history.push(`/${getkey()}`)} variant="contained" color="primary">
                                    Back to List
                                </Button>
                            </Box>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </AppLayout>
    )
}
