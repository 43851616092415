import React , { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { grey } from '@material-ui/core/colors';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import ThankPage from './views/thankYou';
import surveyForm from './views/surveyForm';
import surveyListview from './views/surveyListview';

let themeOpts =
{
  palette: {
    type: 'light',
    primary: {
      light: '#63a4ff',
      main: '#1976d2',
      dark: '#004ba0',
      contrastText: '#fff',
    },
    secondary: {
      light: '#ffb256',
      main: '#f78125',
      dark: '#be5200',
      contrastText: '#fff',
    },
    background: {
      default: grey[100],
    }
  }
}

let themeDarkOpts =
{
  palette: {
    type: 'dark',
    primary: {
      light: '#ffff55',
      main: '#fcda08',
      dark: '#c4a900',
      contrastText: '#000',
    },
    secondary: {
      light: '#ffc1e3',
      main: '#f48fb1',
      dark: '#bf5f82',
      contrastText: '#000',
    },
  }
}


const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function Routers() {
  //const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const prefersDarkMode = false;
  const theme = React.useMemo(() => createTheme(prefersDarkMode ? themeDarkOpts : themeOpts), [prefersDarkMode]);
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/thankyou" component={ThankPage} />
          <Route exact path="/form/:id" component={surveyForm} />
          <Route exact path="/:id" component={surveyListview} />
        </Switch>
      </Router>
    </ThemeProvider>
  )
}

ReactDOM.render(<Routers />, document.getElementById('root'));
