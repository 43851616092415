const setLocalStorage = (key, val) => { localStorage.setItem(key, val) };
const getLocalStorage = (key) => { return localStorage.getItem(key) };
export const setkey = (tokenStr) => { return setLocalStorage('key', tokenStr) };
export const getkey = () => { return getLocalStorage('key') };

//RESTful API fetch
const getApiUrl = (path) => {
	return `/api${path.startsWith('/') ? '' : '/'}${path}`
};
const getApiConf = (method, jsonObj) => {
	let conf = { 'method': method, headers: {} };
	if (method.toUpperCase() !== "GET") { conf.headers = { 'Content-Type': 'application/json' } }
	if (jsonObj) { conf.body = JSON.stringify(jsonObj) }
	return conf;
};
export const fetchAPI = (method, url, jsonObj) => {
	return new Promise(function (resolve, reject) {
		//send request    
		fetch(getApiUrl(url), getApiConf(method, jsonObj))
			.then((response) => {
				if (response.status === 200) { //success respones
					return response.json()
				} else if (response.status === 401) {
					document.location.href = "/";
				}
			})
			.then((respJson) => {
				resolve(respJson);
			})
			.catch((err) => {
				reject(err)
			});
	});
};
