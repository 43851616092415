import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Container, Button, Typography, Radio, RadioGroup, Box, Grid, Paper, Hidden } from '@material-ui/core';
import { Formik, Form } from 'formik';
import { useParams, useHistory } from "react-router-dom";
import AppLayout from '../layout/app';
import { fetchAPI } from '../utils';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 140,
        width: 100,
    },
    control: {
        padding: theme.spacing(2),
    },
    formLable: {
        textAlign: 'center'
    }
}));

export default function SurveyForm() {

    const classes = useStyles();
    const history = useHistory();
    const [q1, setQ1] = useState('');
    const [q2, setQ2] = useState('');
    const [q3, setQ3] = useState('');
    const [q4, setQ4] = useState('');
    const [q5, setQ5] = useState('');
    const [q6, setQ6] = useState('');
    const [type, setType] = useState('Q1');
    const [completedBy, setCompleteBy] = useState('');
    const [surveyfor, setSurveyFor] = useState('');
    const [formID, setFormID] = useState('');
    const [comepletedbyID, setcomepletedbyID] = useState('');
    const [error, setError] = React.useState(false);
    let { id } = useParams();

    const getFormUsers = () => {
        fetchAPI('GET', `survey/${id}`).then(respObj => {
            console.log(respObj)
            setType(respObj.type)
            setCompleteBy(respObj.appraisalCompleteBy.Name)
            setcomepletedbyID(respObj.appraisalCompleteBy._id)
            setSurveyFor(respObj.appraisalFor.Name)
            setFormID(respObj._id)
        }).catch();
    }

    useEffect(() => {
        getFormUsers();
    }, []);

    const handleSubmit = (values) => {
        let result = {
            "answers": `${values.q1},${values.q2},${values.q3},${values.q4},${values.q5},${values.q6}`
        }
        fetchAPI('PATCH', `survey/${formID}`, result)
            .then((rep) => {
                //this.userObj = null;
                console.log(rep)
                if (rep.id != "") {
                    // history.push(`/${comepletedbyID}`);
                    history.push(`/thankyou`);
                }
            }).catch((err) => { alert(err.message) });
    };

    let initialData = {
        q1: q1,
        q2: q2,
        q3: q3,
        q4: q4,
        q5: q5,
        q6: q6,
    }

    return (
        <AppLayout>
            <Container maxWidth="md">
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    style={{ minHeight: '100vh', maxWidth: '100%' }}
                >
                    <Grid item xs={12} align="center">
                        <Box style={{ backgroundColor: "white" }} py={2}>
                            <img style={{ borderRadius: "10px" }} src="/imgs/bank.png" width="65%" alt="bank" />
                        </Box>
                    </Grid>
                    <Grid item xs={12} align="left">
                        <Paper elevation={0}>
                            <Box p={3}>
                                <Typography variant="h5" style={{ fontWeight: 600 }}>2024 Year End Behavioural Appraisal Form</Typography>
                                <Typography variant="body2" style={{ marginTop: "10px" }}>
                                    Dear colleagues,
                                </Typography>
                                <Typography variant="body2" style={{ marginTop: "10px" }}>
                                    As we close off 2024, it is time for us to take stock of our individual achievements for the year.
                                </Typography>
                                <Typography variant="body2">
                                    We will now kick-start the 2024 Performance Appraisal Cycle.
                                </Typography>
                                <Typography variant="body2">
                                    Please submit your scoring for Section E Behavior Appraisal for the employee according to the scoring table as below:
                                </Typography>
                                <Typography variant="body2" style={{ marginTop: "10px" }}>
                                    95 : Behaviour Exceeds Expected Standards（表现超出你的预期）
                                </Typography>
                                <Typography variant="body2">
                                    85 : Behaviour Met Expected Standards（表现符合你的预期）
                                </Typography>
                                <Typography variant="body2">
                                    75 : Behaviour Marginally Met Expected Standards（表现基本达到预期）
                                </Typography>
                                <Typography variant="body2">
                                    50 : Behaviour Do not Always Meet Expected Standards（表现并非总是达到预期)
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} align="left">
                        <Paper elevation={0}>
                            <Box p={3}>
                                <Typography variant="body1">Appraisal For:</Typography>
                                <Typography variant="h6">
                                    {surveyfor}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} align="left">
                        <Paper elevation={0}>
                            <Box p={3}>
                                <Typography variant="body1">Appraisal Completed By:</Typography>
                                <Typography variant="h6">
                                    {completedBy}
                                </Typography>
                            </Box>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} align="left">
                        <Paper elevation={0}>
                            <Formik
                                initialValues={initialData}
                                enableReinitialize={true}
                                validate={values => {
                                    let errors = {};

                                    if (type == "Q1") {
                                        if (!values.q1) { errors.q1 = 'Please select a value'; }
                                        if (!values.q2) { errors.q2 = 'Please select a value'; }
                                        if (!values.q3) { errors.q3 = 'Please select a value'; }
                                        if (!values.q4) { errors.q4 = 'Please select a value'; }
                                        if (!values.q5) { errors.q5 = 'Please select a value'; }
                                        if (!values.q6) { errors.q6 = 'Please select a value'; }
                                    } else {
                                        if (!values.q1) { errors.q1 = 'Please select a value'; }
                                        if (!values.q2) { errors.q2 = 'Please select a value'; }
                                        if (!values.q3) { errors.q3 = 'Please select a value'; }
                                        if (!values.q4) { errors.q4 = 'Please select a value'; }
                                    }
                                    return errors;
                                }}
                                onSubmit={(values) => {
                                    handleSubmit(values);
                                }}
                            >
                                {({ submitForm, errors, touched }) => (
                                    <Form>
                                        <Box p={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{ fontWeight: 600 }}>1. Operational Efficiency 工作效率</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">
                                                        Diligence in delivering high quality of work effectively and efficiently. Demonstrate problem-solving skills at work. Continuously seek ways to complete tasks more effectively.
                                                    </Typography>
                                                    <Typography variant="body2" style={{ marginTop: "5px" }}>
                                                        勤劳刻苦，工作表现高质高效。 工作中表现出解决问题的能力。 不断寻求更有效的方法完成任务。
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box p={1}>
                                                        <Grid container spacing={2}>
                                                            <Hidden xsDown><Grid item md={3}></Grid></Hidden>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>95</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>85</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>75</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>50</Grid>
                                                            <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                        </Grid>
                                                    </Box>
                                                    <Box style={{ background: '#efefef' }} p={1}>
                                                        <RadioGroup name="q1" onChange={e => setQ1(e.target.value)}>
                                                            <Grid container spacing={2}>
                                                                <Hidden xsDown><Grid item md={3}>
                                                                    <Typography variant="body2">Operational Efficiency</Typography>
                                                                    <Typography variant="body2">工作效率</Typography>
                                                                </Grid></Hidden>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"95"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"85"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"75"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"50"} /></Grid>
                                                                <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                            </Grid>
                                                        </RadioGroup>
                                                    </Box>
                                                    <Typography variant="body2" style={{ marginTop: "5px", color: "red", fontFamily: "sans-serif" }}>
                                                        {errors.q1}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box p={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6" style={{ fontWeight: 600 }}>2. Collaboration 配合协调</Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">
                                                        Ability to work well with people, and establish co-operative and productive relationships.
                                                    </Typography>
                                                    <Typography variant="body2" style={{ marginTop: "5px" }}>
                                                        能够与人合作，建立紧密合作和生产力高的工作关系。
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box p={1}>
                                                        <Grid container spacing={2}>
                                                            <Hidden xsDown><Grid item md={3}></Grid></Hidden>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>95</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>85</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>75</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>50</Grid>
                                                            <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                        </Grid>
                                                    </Box>
                                                    <Box style={{ background: '#efefef' }} p={1}>
                                                        <RadioGroup name="q2" onChange={e => setQ2(e.target.value)}>
                                                            <Grid container spacing={2}>
                                                                <Hidden xsDown><Grid item md={3}>
                                                                    <Typography variant="body2">Collaboration</Typography>
                                                                    <Typography variant="body2">配合协调</Typography>
                                                                </Grid></Hidden>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"95"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"85"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"75"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"50"} /></Grid>
                                                                <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                            </Grid>
                                                        </RadioGroup>
                                                    </Box>
                                                    <Typography variant="body2" style={{ marginTop: "5px", color: "red", fontFamily: "sans-serif" }}>
                                                        {errors.q2}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box p={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6"><b>3. Integrity 廉洁自律</b></Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">
                                                        Lead by example in demonstrating the highest standards of business ethics and complying with regulatory requirements, never beating the system or side-stepping determined standards/ procedures to achieve goals.
                                                    </Typography>
                                                    <Typography variant="body2" style={{ marginTop: "5px" }}>
                                                        以身作则，展示最高标准的商业道德并遵守监管要求，从不为了达到目标而逾越系统或绕过有关标准/程序。
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box p={1}>
                                                        <Grid container spacing={2}>
                                                            <Hidden xsDown><Grid item md={3}></Grid></Hidden>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>95</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>85</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>75</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>50</Grid>
                                                            <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                        </Grid>
                                                    </Box>
                                                    <Box style={{ background: '#efefef' }} p={1}>
                                                        <RadioGroup name="q3" onChange={e => setQ3(e.target.value)}>
                                                            <Grid container spacing={2}>
                                                                <Hidden xsDown><Grid item md={3}>
                                                                    <Typography variant="body2">Integrity</Typography>
                                                                    <Typography variant="body2">廉洁自律</Typography>
                                                                </Grid></Hidden>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"95"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"85"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"75"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"50"} /></Grid>
                                                                <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                            </Grid>
                                                        </RadioGroup>
                                                    </Box>
                                                    <Typography variant="body2" style={{ marginTop: "5px", color: "red", fontFamily: "sans-serif" }}>
                                                        {errors.q3}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <Box p={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <Typography variant="h6"><b>4. Service Level 服务素质</b></Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">
                                                        Consistently able to provide high-quality services to internal / external customers in a professional and timely manner.
                                                    </Typography>
                                                    <Typography variant="body2" style={{ marginTop: "5px" }}>
                                                        始终如一地以专业和及时的方式，不论对内或对外都能坚持提供高质量的服务。
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Box p={1}>
                                                        <Grid container spacing={2}>
                                                            <Hidden xsDown><Grid item md={3}></Grid></Hidden>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>95</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>85</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>75</Grid>
                                                            <Grid item md={2} xs={3} className={classes.formLable}>50</Grid>
                                                            <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                        </Grid>
                                                    </Box>
                                                    <Box style={{ background: '#efefef' }} p={1}>
                                                        <RadioGroup name="q4" onChange={e => setQ4(e.target.value)}>
                                                            <Grid container spacing={2}>
                                                                <Hidden xsDown><Grid item md={3}>
                                                                    <Typography variant="body2">Service Level</Typography>
                                                                    <Typography variant="body2">服务素质</Typography>
                                                                </Grid></Hidden>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"95"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"85"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"75"} /></Grid>
                                                                <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"50"} /></Grid>
                                                                <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                            </Grid>
                                                        </RadioGroup>
                                                    </Box>
                                                    <Typography variant="body2" style={{ marginTop: "5px", color: "red", fontFamily: "sans-serif" }}>
                                                        {errors.q4}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        {type === 'Q1' ? (
                                            <div>
                                                <Box p={3}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6"><b>5. Transformation and Innovation 转型创新</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body2">
                                                                Willingness to take on new ideas, innovations or concepts and make improvements and changes to existing ways to achieve efficiency and effectiveness.
                                                            </Typography>
                                                            <Typography variant="body2" style={{ marginTop: "5px" }}>
                                                                愿意接纳新想法、创意或概念，并改进和改变现有方式以提高效率和有效性
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box p={1}>
                                                                <Grid container spacing={2}>
                                                                    <Hidden xsDown><Grid item md={3}></Grid></Hidden>
                                                                    <Grid item md={2} xs={3} className={classes.formLable}>95</Grid>
                                                                    <Grid item md={2} xs={3} className={classes.formLable}>85</Grid>
                                                                    <Grid item md={2} xs={3} className={classes.formLable}>75</Grid>
                                                                    <Grid item md={2} xs={3} className={classes.formLable}>50</Grid>
                                                                    <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                                </Grid>
                                                            </Box>
                                                            <Box style={{ background: '#efefef' }} p={1}>
                                                                <RadioGroup name="q5" onChange={e => setQ5(e.target.value)}>
                                                                    <Grid container spacing={2}>
                                                                        <Hidden xsDown><Grid item md={3}>
                                                                            <Typography variant="body2">Transformation and Innovation</Typography>
                                                                            <Typography variant="body2">转型创新</Typography>
                                                                        </Grid></Hidden>
                                                                        <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"95"} /></Grid>
                                                                        <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"85"} /></Grid>
                                                                        <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"75"} /></Grid>
                                                                        <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"50"} /></Grid>
                                                                        <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                                    </Grid>
                                                                </RadioGroup>
                                                            </Box>
                                                            <Typography variant="body2" style={{ marginTop: "5px", color: "red", fontFamily: "sans-serif" }}>
                                                                {errors.q5}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box p={3}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <Typography variant="h6"><b>6. Team Building 队伍建设</b></Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography variant="body2">
                                                                Ability to build and lead a team, and to motivate team members to excel in their job.
                                                            </Typography>
                                                            <Typography variant="body2" style={{ marginTop: "5px" }}>
                                                                能够建立和领导一个团队，并激励团队成员在工作上努力表现。
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Box p={1}>
                                                                <Grid container spacing={2}>
                                                                    <Hidden xsDown><Grid item md={3}></Grid></Hidden>
                                                                    <Grid item md={2} xs={3} className={classes.formLable}>95</Grid>
                                                                    <Grid item md={2} xs={3} className={classes.formLable}>85</Grid>
                                                                    <Grid item md={2} xs={3} className={classes.formLable}>75</Grid>
                                                                    <Grid item md={2} xs={3} className={classes.formLable}>50</Grid>
                                                                    <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                                </Grid>
                                                            </Box>
                                                            <Box style={{ background: '#efefef' }} p={1}>
                                                                <RadioGroup name="q6" onChange={e => setQ6(e.target.value)}>
                                                                    <Grid container spacing={2}>
                                                                        <Hidden xsDown><Grid item md={3}>
                                                                            <Typography variant="body2">Team Building</Typography>
                                                                            <Typography variant="body2">队伍建设</Typography>
                                                                        </Grid></Hidden>
                                                                        <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"95"} /></Grid>
                                                                        <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"85"} /></Grid>
                                                                        <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"75"} /></Grid>
                                                                        <Grid item md={2} xs={3} className={classes.formLable}><Radio value={"50"} /></Grid>
                                                                        <Hidden xsDown><Grid item md={1}></Grid></Hidden>
                                                                    </Grid>
                                                                </RadioGroup>
                                                            </Box>
                                                            <Typography variant="body2" style={{ marginTop: "5px", color: "red", fontFamily: "sans-serif" }}>
                                                                {errors.q6}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </div>
                                        ) : (<div></div>)}
                                        <Box display="flex" flexDirection="row-reverse" p={3}>
                                            <Button onClick={submitForm} variant="contained" color="primary">
                                                Submit
                                            </Button>
                                            <Button style={{ marginRight: "10px" }} onClick={() => history.push(`/${comepletedbyID}`)} >
                                                Back to List
                                            </Button>
                                        </Box>
                                    </Form>
                                )}</Formik>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </AppLayout >
    )
}
