import React from 'react';
import { withStyles } from '@material-ui/styles';
import {
    CssBaseline, Container,
    Fade, Box, Typography
} from '@material-ui/core';

const drawerWidth = 200;

const styles = theme => ({
    root: {
        display: 'flex',
    },
    logo: {
        width: 180,
        height: 70,
        marginTop: 20,
        marginBottom: 5,
        marginLeft: 15,
        backgroundImage: (theme.palette.type === 'dark' ? 'url("/imgs/bank.png")' : 'url("/imgs/bank.png")'),
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        marginLeft: drawerWidth,
        //backgroundColor: (theme.palette.type === 'dark' ? theme.palette.background.paper : undefined),
        //color: (theme.palette.type === 'dark' && theme.palette.text.primary),
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    title: {
        flexGrow: 1,
    },
    menuAvatar: {
        width: 28,
        height: 28,
        fontSize: 14,
        //color: (theme.palette.type === 'dark' ? theme.palette.background.paper : theme.palette.primary.main),
        color: '#fff',
        backgroundColor: theme.palette.background.default,
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        [theme.breakpoints.up('sm')]: {
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
        }
    },
    container: {
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(1),
        }
    },
    button: {
        margin: theme.spacing(1),
    },
});

class AppLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = { mobileOpen: false };
    }

    handleDrawerToggle = () => { this.setState({ mobileOpen: !this.state.mobileOpen }) };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <CssBaseline />
                <main className={classes.content}>
                    {/* <div className={classes.toolbar} /> */}
                    <Fade in={true}>
                        <Container maxWidth={false} className={classes.container} >
                            {this.props.children}
                            <Box mt={3} textAlign="center">
                            <Typography variant="caption">This form is automatically generated by Sharker Technology Ptd Ltd, v1.0.1</Typography>
                            </Box>
                        </Container>
                    </Fade>
                </main>
            </div>
        )
    }
}

export default withStyles(styles, { withTheme: true })(AppLayout);